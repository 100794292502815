import { BoxProps, CARDS, COLORS, cards, colors } from "./ReNew";
import "./ReNew.css";
import { Fragment, FunctionComponent } from "react";

const Body: FunctionComponent<{ grid: BoxProps[]; addHistory: (moves: BoxProps[], selection?: boolean) => void }> = ({ grid, addHistory }) => {
  const selectBox = function (index: number) {
    const history = grid.map((move) => ({ ...move }));
    history[index].selected = !history[index].selected;
    addHistory(history, true);
  };

  const updateBoxNumber = function (index: number, value: undefined | CARDS) {
    const history = grid.map((move) => ({ ...move }));
    history[index].boxNumber = value;
    addHistory(history);
  };

  const updateBoxColor = function (index: number, value: COLORS) {
    const history = grid.map((move) => ({ ...move }));
    history[index].color = value;
    addHistory(history);
  };

  return (
    <div id="ReBody">
      {grid.map((box, index) => {
        return (
          <Box
            key={index}
            {...box}
            selectBox={() => selectBox(index)}
            updateBoxNumber={(value) => {
              updateBoxNumber(index, value);
            }}
            updateBoxColor={(value) => {
              updateBoxColor(index, value);
            }}
          />
        );
      })}
    </div>
  );
};

const Box: FunctionComponent<BoxProps> = ({ boxNumber, color, positionName, selected, selectBox, updateBoxColor = () => {}, updateBoxNumber = () => {} }) => {
  return (
    <div id="ReBox" style={{ backgroundColor: color }} onClick={selectBox}>
      <span className="RePositionName">{positionName}</span>
      {selected ? <div className="selected" /> : <Fragment />}
      <span className="ReBoxNumber">{boxNumber}</span>

      {/* COLORS */}
      <div className="colorsColumn">
        {colors.map((color, index) => {
          return (
            <span
              key={index}
              onClick={(event) => {
                event.stopPropagation();
                updateBoxColor(color);
              }}
              style={{ backgroundColor: color }}
            />
          );
        })}
      </div>

      {/* NUMBERS */}
      <div className="numbersColumn">
        {cards.map((number, index) => {
          return (
            <span
              key={index}
              onClick={(event) => {
                event.stopPropagation();
                updateBoxNumber(number);
              }}
            >
              {number}
            </span>
          );
        })}
        <span
          onClick={(event) => {
            event.stopPropagation();
            updateBoxNumber(undefined);
          }}
        />
      </div>
    </div>
  );
};

export { Body };
