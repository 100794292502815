import "./Projects.css";
import { FunctionComponent } from "react";

const URL_REGEX = "^(https)";
const regEx = new RegExp(URL_REGEX);

type device = "android" | "ios";

type project = {
  title: string;
  body: string;
  links: {
    [key in device]?: string;
  };
  preview: string;
};

const projects: project[] = [
  {
    title: "S.Maria Degli Angeli",
    body: 'S.Maria Degli Angeli is an app developed in React Native (v0.59) in Netcom Group, based on virtual reality. It was the first FrontEnd project I worked on solo. To create the VR environments, I used the "ViroReact" framework from Viro Media (now open source), which provides well-documented APIs. The download is only available for Android devices.',
    links: {
      android: "https://play.google.com/store/apps/details?id=com.chiesabrienza&gl=IT",
    },
    preview: "brienzaPreview",
  },
  {
    title: "Eufarma",
    body: "Eufarma is an app developed in React Native (v0.60) for Android and iOS devices. For the creation Netcom Group dedicated an entire team of which I was part, led by Francesco Picazio https://www.linkedin.com/in/francescopicazio who still deals with the maintenance and expansion of the functions.",
    links: {
      android: "https://play.google.com/store/apps/details?id=com.eufarma&gl=IT",
      ios: "https://apps.apple.com/it/app/eufarma/id1489298745",
    },
    preview: "eufarmaPreview",
  },
  {
    title: "Rondo JS",
    body: "Rondo is a Discord BOT written in JS based on events, and deployed on my Debian Server. It help players to do some repetitive tasks in a videogame.",
    links: {},
    preview: "TODO",
  },
  {
    title: "Kronos",
    body: "Kronos is a Server-Client application with Monolithic architecture, capable of carrying out some actions on Sky Q devices. Developed entirely on its own, it combines all the experience accumulated in previous projects. The Frontend was created in Javascript with AngularJS (v1.6.9) and Bootstrap 4. For the Backend instead, Flask on Python 3.6, a framework that allows you to expose your restAPI, finally as a database, mongoDB. The entire project is completely versioned via GIT on Gitlab, and documented in Markdown. Kronos is an internal Sky application not publicly available.",
    links: {},
    preview: "kronosPreview",
  },
  {
    title: "ASID 3.0",
    body: 'ASID (version 3.0) is a Server-Client application capable of capturing the video output of a device connected via HDMI, and subsequently carrying out certain checks on the saved recordings.\nOriginally developed in AngularJS and bootstrap 3 by Martin Tomasevic https://it.linkedin.com/in/martin-tomasevic, was the first personal "porting" experience: the web-app was entirely converted from AngularJS to React 16.\nASID is an internal Sky application not publicly available.',
    links: {},
    preview: "asid1",
  },
  {
    title: "Venus",
    body: "Venus is a Server-Client application, capable of carrying out a series of tests on Sky devices.\nDeveloped by Francesco Colangelo https://it.linkedin.com/in/francescocolangelo80, it is the application that I still follow and update today constantly.\nThe AngularJS-Bootstrap 4 combination was chosen for the Frontend, while the Backend is managed via CherryPy on the MongoDB database.\nEach server interfaces with the Q devices via Ethernet to a Linux program developed and maintained by Lam Dinh https://www.linkedin.com/in/tung-lam-dinh-90828136/.\nVenus is a Sky application not publicly available.",
    links: {},
    preview: "venusPreview",
  },
  {
    title: "Drippato.it",
    body: 'This site is written in React 16.14 and is hosted via Apache v2.4 on a RaspberryPi 3B+ (OS Debian 10 "Buster").',
    links: {},
    preview: "pagliucadb1",
  },
];

const Project: FunctionComponent<project> = ({ body, links, preview, title }) => {
  return (
    <div id="Project">
      <h2>{title}</h2>
      {body.split("\n").map((item, index) => {
        return (
          <span key={index}>
            {item.split(" ").map((word, index) => {
              return (
                <span key={index}>
                  {regEx.test(word) ? (
                    <a href={word} target="_blank" rel="noreferrer">
                      (Link)
                    </a>
                  ) : (
                    word
                  )}{" "}
                </span>
              );
            })}
          </span>
        );
      })}
    </div>
  );
};

const Projects: FunctionComponent = () => {
  return (
    <div id="Projects">
      <h1>Projects</h1>
      {projects.map((item, index) => {
        return <Project key={index} {...item} />;
      })}
    </div>
  );
};

export default Projects;
