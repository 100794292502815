import "./PersonalDetails.css";
import { FunctionComponent } from "react";
import ContactSection from "./components/ContactSection";
import ExperienceSection from "./components/ExperienceSection";
import HeaderSection from "./components/HeaderSection";
import reactLogo from "./resources/react_logo.png";
import Projects from "./components/Projects";

const PersonalDetails: FunctionComponent = () => {
  document.title = "My portfolio";
  return (
    <div id="PersonalDetails">
      <img src={reactLogo} alt="ReactLogo" className="reactLogo" />
      <div>
        <HeaderSection />
        <ExperienceSection />
        <Projects />
        <ContactSection />
      </div>
    </div>
  );
};

export default PersonalDetails;
