import "./ReNew.css";
import { FunctionComponent } from "react";
import { BoxProps, COLORS, CARDS, cards, colors } from "./ReNew";
import one from "../../resources/1.png";
import two from "../../resources/2.png";
import three from "../../resources/3.png";
import four from "../../resources/4.png";
import five from "../../resources/5.png";
import king from "../../resources/K.png";
// import resetIcon from "../../resources/reload.png";

type statistics = {
  [key in CARDS]: number;
};

type Stat = {
  source: string;
  quantity: number;
  found: number;
};

const Footer: FunctionComponent<{ grid: BoxProps[]; addHistory: (moves: BoxProps[]) => void }> = ({ grid, addHistory }) => {
  const choices: (undefined | CARDS)[] = [...cards, undefined];

  const statistics = grid.reduce((accumulator, current) => {
    const { boxNumber } = current;
    if (!boxNumber) return accumulator;

    if (Object.keys(accumulator).includes(boxNumber.toString())) {
      accumulator[boxNumber] = accumulator[boxNumber] + 1;
      return accumulator;
    }

    accumulator[boxNumber] = 1;
    return accumulator;
  }, {} as statistics);

  const stats: Stat[] = [
    { source: one, quantity: 7, found: statistics[CARDS.ONE] | 0 },
    { source: two, quantity: 4, found: statistics[CARDS.TWO] | 0 },
    { source: three, quantity: 5, found: statistics[CARDS.THREE] | 0 },
    { source: four, quantity: 5, found: statistics[CARDS.FOUR] | 0 },
    { source: five, quantity: 3, found: statistics[CARDS.FIVE] | 0 },
    { source: king, quantity: 1, found: statistics[CARDS.KING] | 0 },
  ];

  const setColor = function (color: COLORS) {
    const history = grid.map((move) => ({ ...move }));
    history.forEach((cell) => {
      if (cell.selected) {
        cell.color = color;
        cell.selected = false;
      }
    });
    // console.table(history);
    addHistory(history);
  };

  const setBoxNumber = function (boxNumber: undefined | CARDS) {
    const history = grid.map((move) => ({ ...move }));
    history.forEach((cell) => {
      if (cell.selected) {
        cell.boxNumber = boxNumber;
        cell.selected = false;
      }
    });
    addHistory(history);
  };

  return (
    <div id="ReFooter">
      {/* PALETTE */}
      <div id="RePalette">
        {colors.map((color, index) => {
          return <span title={color} key={index} style={{ backgroundColor: color }} onClick={() => setColor(color)} />;
        })}
      </div>

      {/* CHOICES */}
      <div id="ReChoices">
        {choices.map((boxNumber, index) => {
          return (
            <span
              key={index}
              onClick={() => {
                setBoxNumber(boxNumber);
              }}
            >
              {boxNumber ? boxNumber : ""}
            </span>
          );
        })}
      </div>

      {/* STATISTICS */}
      <div id="ReStatistics">
        {stats.map((item, index) => {
          return <Statistic {...item} key={index} />;
        })}
      </div>

      {/* <div id="ReHandlers">
        <img src={resetIcon} alt="Reset" onClick={() => setGrid(generateGrid())} />
      </div> */}
    </div>
  );
};

const Statistic: FunctionComponent<Stat> = ({ found, quantity, source }) => {
  return (
    <div id="Stat">
      <img src={source} alt={source} />
      <span>
        {quantity - found >= 0 ? "x" : ""}
        {quantity - found}
      </span>
    </div>
  );
};

export { Footer };
