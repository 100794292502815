import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.defaults.timeout = 2000;
axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    try {
      if (error?.code === "ECONNABORTED") {
        const message = "Looks like the server is taking too long to respond, please try again in some time";
        error["data"] = message;
        error["status"] = 408;
        return error;
      }

      return error.response;
    } catch (error) {
      return { status: 500, data: "Backend not reachable. Check your connection." };
    }
  }
);

export default axiosInstance;
