import "./Okey.css";
import { Fragment, FunctionComponent } from "react";
import { Card, card } from "./Card";

interface combination {
  tris: [card, card, card];
  score: number;
}

const sortingAlgorithm = (a: combination, b: combination) => {
  if (a.score < b.score) return 1;
  if (a.score > b.score) return -1;
  if (a.score === b.score) {
    if (a.tris[0].cardNumber < b.tris[0].cardNumber) return -1;
    return 1;
  }
  return 0;
};

const generateScoreboard = function (cards: card[]) {
  const availableScoreboard: combination[] = [];
  const usedTrisNumbers: number[] = [];

  // FROM ALL UNUSED CARD
  for (let i = 0; i < cards.length; i++) {
    for (let j = 0; j < cards.length; j++) {
      for (let k = 0; k < cards.length; k++) {
        // TAKE CARDS
        const firstCard = cards[i];
        const secondCard = cards[j];
        const thirdCard = cards[k];

        if (firstCard.cardNumber + 1 === secondCard.cardNumber && secondCard.cardNumber + 1 === thirdCard.cardNumber) {
          // ALL THREE CARDS ARE IN SEQUENCE (EG: 6-7-8)

          if (firstCard.cardColor !== secondCard.cardColor || firstCard.cardColor !== thirdCard.cardColor) {
            // BUT WITH DIFFERENT COLORS (EG 6 BLUE - 7 RED - 8 YELLOW)
            availableScoreboard.push({
              tris: [cards[i], cards[j], cards[k]],
              score: firstCard.cardNumber * 10,
            });
          }

          //DI COLORE UGUALE
          if (firstCard.cardColor === secondCard.cardColor && firstCard.cardColor === thirdCard.cardColor) {
            // BUT WITH SAME COLORS (EG 6 RED - 7 RED - 8 RED)
            availableScoreboard.push({
              tris: [cards[i], cards[j], cards[k]],
              score: thirdCard.cardNumber * 10 + 20,
            });
          }
        }

        if (
          firstCard.cardNumber === secondCard.cardNumber &&
          secondCard.cardNumber === thirdCard.cardNumber &&
          firstCard.cardColor !== secondCard.cardColor &&
          firstCard.cardColor !== thirdCard.cardColor &&
          secondCard.cardColor !== thirdCard.cardColor &&
          !usedTrisNumbers.includes(firstCard.cardNumber)
        ) {
          // ALL THREE CARDS HAS SAME NUMBER (EG 6-6-6)
          availableScoreboard.push({
            tris: [cards[i], cards[j], cards[k]],
            score: thirdCard.cardNumber * 10 + 10,
          });
          // REMEMBER TO HAVE ALREADY ADDED IT DO SKIP DUPLICATES
          usedTrisNumbers.push(firstCard.cardNumber);
        }
      }
    }
  }

  return availableScoreboard;
};

const Score: FunctionComponent<{ combination: combination }> = ({ combination }) => {
  const { tris, score } = combination;

  return (
    <div id="OkeyScore">
      {tris.map((card, index) => {
        return <Card key={index} card={card} selectCard={() => {}} />;
      })}
      <span>{score}</span>
    </div>
  );
};

const OkeyCombinations: FunctionComponent<{ cards: card[] }> = ({ cards }) => {
  const scoreboard = generateScoreboard(cards).sort(sortingAlgorithm);

  return (
    <div id="OkeyCombinations">
      <h1>Available best Combinations</h1>
      {scoreboard.map((combination, index) => {
        return <Score key={index} combination={combination} />;
      })}
      {scoreboard.length === 0 ? <h1>No available combinations</h1> : <Fragment />}
    </div>
  );
};

export default OkeyCombinations;
