import "./ExperienceSection.css";
import { Fragment, FunctionComponent } from "react";

enum Companies {
  NETCOM = "Netcom Group",
  ERICSSON = "Ericsson",
  SKY = "Sky Italia",
}

interface singleExperience {
  duration: string;
  city: string;
  task: string;
  description: string;
  company: Companies;
}

interface bulletExperience extends singleExperience {
  showLine?: boolean;
}

const TOTORIMUOVERE: bulletExperience[] = [
  {
    duration: "12/06/2019 - 30/11/2019",
    city: "Naples",
    task: "Internship",
    description: "R&D Internship at Netcom Group",
    company: Companies.NETCOM,
  },
  {
    duration: "01/12/2019 - 30/11/2020",
    city: "Milan",
    task: "Software Developer",
    description: "IT consultancy at Sky Italia",
    company: Companies.NETCOM,
  },
  {
    duration: "01/12/2020 - 14/04/2021",
    city: "Naples",
    task: "Database Mantainer",
    description: "IT consultancy at Ericsson Italia",
    company: Companies.NETCOM,
  },
  {
    duration: "15/04/2021 - Current",
    city: "Milan",
    task: "Software Developer",
    description: "IT consultancy at Sky Italia",
    company: Companies.NETCOM,
  },
];

const SingleExperience: FunctionComponent<bulletExperience> = ({ company, city, description, duration, task, showLine = true }) => {
  return (
    <div id="SingleExperience">
      <div>{showLine ? <div className="lineConnect" /> : <Fragment />}</div>
      <h2>{task}</h2>
      <h3>{description}</h3>
      <span>{company}</span>
      <span>{duration}</span>
      <span>{city}</span>
    </div>
  );
};

const ExperienceSection: FunctionComponent = () => {
  return (
    <div id="ExperienceSection">
      <h1>Work Experience</h1>
      {TOTORIMUOVERE.slice(0)
        .reverse()
        .map((exeprience, index) => {
          index + 1 === TOTORIMUOVERE.length ? (exeprience.showLine = false) : (exeprience.showLine = true);
          return <SingleExperience {...exeprience} key={index} />;
        })}
    </div>
  );
};

export default ExperienceSection;
