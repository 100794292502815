import "./ContactSection.css";
import { FunctionComponent } from "react";
import facebookIcon from "../resources/facebook.svg";
import discordIcon from "../resources/discord.svg";
import githubIcon from "../resources/github.svg";
import gmailIcon from "../resources/gmail.svg";
import instagramIcon from "../resources/instagram.svg";
import linkedinIcon from "../resources/linkedin.svg";
import skypeIcon from "../resources/skype.svg";
import XIcon from "../resources/x.svg";

type contact = {
  name: string;
  link: string;
  logo: string;
  target: string;
};

const contacts: contact[] = [
  {
    name: "Linkedin",
    link: "https://it.linkedin.com/in/giovanni-pagliuca",
    logo: linkedinIcon,
    target: "_blank",
  },
  {
    name: "Github",
    link: "https://github.com/mimmobordini",
    logo: githubIcon,
    target: "_blank",
  },
  {
    name: "Gmail",
    link: "mailto:g.pagliuca1994@gmail.com",
    logo: gmailIcon,
    target: "_self",
  },
  {
    name: "Skype",
    link: "https://join.skype.com/invite/l6MbocrShFoS",
    logo: skypeIcon,
    target: "_blank",
  },
  {
    name: "X",
    link: "https://twitter.com/ANONOPS_Jonny",
    logo: XIcon,
    target: "_blank",
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/gpagliuca1",
    logo: facebookIcon,
    target: "_blank",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/sapphire_fever",
    logo: instagramIcon,
    target: "_blank",
  },
  {
    name: "Discord",
    link: "https://discordapp.com/users/232890307518267402",
    logo: discordIcon,
    target: "_blank",
  },
];

const Contact: FunctionComponent<contact> = ({ link, logo, name, target }) => {
  return (
    <a id="Contact" target={target} href={link} title={name}>
      <img src={logo} alt={name} />
    </a>
  );
};

const ContactSection: FunctionComponent = () => {
  return (
    <div id="ContactSection">
      <h1>Follow me</h1>

      {contacts.map((item, index) => {
        return <Contact key={index} {...item} />;
      })}
    </div>
  );
};

export default ContactSection;
