import { FunctionComponent } from "react";
import { Card, card } from "./Card";
import "./Okey.css";

type okeyField = {
  cards: card[];
  selectCard: (index: number) => void;
};

const OkeyField: FunctionComponent<okeyField> = ({ cards, selectCard }) => {
  return (
    <div id="OkeyField">
      {cards.map((card, index) => {
        return <Card key={index} card={card} selectCard={() => selectCard(index)} />;
      })}
    </div>
  );
};

export default OkeyField;
