import "./MapRoute.css";
import { FunctionComponent, useState, MouseEvent, Fragment, ChangeEvent, useEffect, useCallback } from "react";
import RifugioBoreale from "../../resources/RifugioBoreale.png";
import closeIcon from "../../resources/x.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../../axiosConfig.js";

const URL = `https://drippato.it/api`;
// const url = `http://127.0.0.1:5000`;

enum Channel {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
}

const channels: { label: string; value: Channel }[] = [
  {
    label: "1",
    value: Channel.One,
  },
  {
    label: "2",
    value: Channel.Two,
  },
  {
    label: "3",
    value: Channel.Three,
  },
  {
    label: "4",
    value: Channel.Four,
  },
  {
    label: "5",
    value: Channel.Five,
  },
  {
    label: "6",
    value: Channel.Six,
  },
];

type Room = {
  color: string;
  rooms: string;
  coords?: Coordinates[];
  showOnHover: boolean;
};

type Coordinates = {
  topLeft: [number, number];
  bottomRight: [number, number];
};

const rooms: Room[] = [
  {
    showOnHover: false,
    color: "white",
    rooms: "1-9-8-5-4-3",
    coords: [
      {
        topLeft: [20, 405],
        bottomRight: [250, 620],
      },
      {
        topLeft: [0, 620],
        bottomRight: [65, 685],
      },
    ],
  },

  {
    showOnHover: false,
    color: "orange",
    rooms: "19-16-13-10",
    coords: [
      {
        topLeft: [145, 630],
        bottomRight: [460, 675],
      },
      {
        topLeft: [515, 375],
        bottomRight: [570, 435],
      },
    ],
  },
  {
    showOnHover: false,
    color: "yellow",
    rooms: "29-28-23-22-25",
    coords: [
      {
        topLeft: [570, 270],
        bottomRight: [750, 520],
      },
      {
        topLeft: [685, 520],
        bottomRight: [750, 685],
      },
    ],
  },

  {
    showOnHover: false,
    color: "blue",
    rooms: "21-24-26-27",
    coords: [
      {
        topLeft: [570, 15],
        bottomRight: [750, 270],
      },
    ],
  },
  {
    showOnHover: false,
    color: "white",
    rooms: "6-2",
    coords: [
      {
        topLeft: [90, 75],
        bottomRight: [225, 130],
      },
    ],
  },
  {
    showOnHover: false,
    color: "green",
    rooms: "11-30",
    coords: [
      {
        topLeft: [240, 120],
        bottomRight: [380, 450],
      },
    ],
  },
];

const roomNumberList = {
  1: [30, 655],
  2: [115, 100],
  3: [95, 425],
  4: [95, 495],
  5: [95, 575],
  6: [195, 100],
  7: [205, 205],
  8: [225, 500],
  9: [225, 580],
  10: [165, 655],
  11: [265, 150],
  12: [340, 420],
  13: [370, 655],
  14: [398, 50],
  15: [428, 335],
  16: [428, 655],
  17: [480, 120],
  18: [505, 175],
  19: [535, 405],
  20: [493, 586],
  21: [587, 50],
  22: [587, 330],
  23: [587, 470],
  24: [637, 115],
  25: [650, 420],
  26: [716, 55],
  27: [716, 250],
  28: [716, 470],
  29: [716, 658],
  30: [303, 360],
};

enum MobType {
  Condottiero = "Condottiero",
  Generale = "Generale",
}

interface Coordinate {
  x: number;
  y: number;
  channel?: Channel;
  mobType: MobType;
  user: string;
  showOnHover: boolean;
  session: string;
  timestamp: number;
  _id?: string;
}

const formatTime = (timestamp: number) => {
  return new Date(timestamp).toLocaleTimeString("it-IT", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const generateRandomString = (length: number): string => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const addPointToBackend: (coordinate: Coordinate) => Promise<boolean> = async (coordinate) => {
  const url = `${URL}/add_point`;
  const body = { coordinate: coordinate };
  const response = await axiosInstance.post(url, body);

  if (response.status === 200) return true;
  return false;
};

const getPointsFromBackend: (session: string) => Promise<Coordinate[]> = async (session) => {
  const url = `${URL}/get_points?session=${session}`;
  const response = await axiosInstance.get(url);
  if (response.status === 200) return response.data as Coordinate[];

  return [];
};

const deletePointFromBackend: (id: string) => Promise<boolean> = async (id) => {
  const body = { id: id };
  const url = `${URL}/delete_point`;

  const response = await axiosInstance.post(url, body);

  if (response.status === 200) return true;
  return false;
};

const MapRoute: FunctionComponent = () => {
  const [username, setUsername] = useState<string>("");
  const [coordinates, setCoordinates] = useState<Coordinate[]>([]);
  const [channelState, setChannelState] = useState<Channel>(Channel.One);
  const [roomsState, setRoomsState] = useState<Room[]>(rooms);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const session: string = searchParams.get("session") || "NO_SESSION";

  const downloadPoints = useCallback(async () => {
    //console.log("DOWNLOADPOINTS");
    const points = await getPointsFromBackend(session);
    setCoordinates(points);

    // return points;
  }, [session]);

  const deleteCoordinate = useCallback(
    async (id: string) => {
      const result = await deletePointFromBackend(id);

      if (result) {
        // const points = await downloadPoints();
        // setCoordinates(points);
        await downloadPoints();
        return;
      }
    },
    [downloadPoints]
  );

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    localStorage.setItem("username", newUsername); // Salva l'username nella cache
  };

  const handleMapClick = async (e: MouseEvent<HTMLImageElement>) => {
    if (username === "") {
      alert("Please specify a username");
      return;
    }
    e.preventDefault();

    const rect = e.currentTarget.getBoundingClientRect();
    const x = Math.round(e.clientX - rect.left);
    const y = Math.round(e.clientY - rect.top);
    const mobType = e.button === 2 ? MobType.Generale : MobType.Condottiero;
    const timestamp = Date.now();
    const coordinate: Coordinate = { x, y, channel: channelState, mobType: mobType, user: username, showOnHover: false, session, timestamp };

    const result = await addPointToBackend(coordinate);

    if (result) {
      // const points = await downloadPoints();
      // setCoordinates(points);
      downloadPoints();
    }
  };

  useEffect(() => {
    //console.log("USEEFFECT");

    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    }

    if (session === "NO_SESSION") {
      // Genera una nuova sessione se non c'è una sessione
      const newSession = generateRandomString(10);

      const newUrl = `${location.pathname}?session=${newSession}`;
      navigate(newUrl, { replace: true });
    }

    downloadPoints();

    const interval = setInterval(async () => {
      //console.log("INTERVAL");
      await downloadPoints();
    }, 3000);

    return function cleanup() {
      //console.log("CLEANUP");

      clearInterval(interval);
    };
  }, [downloadPoints, location.pathname, navigate, session]);

  return (
    <div id="MapRoute" style={{ backgroundImage: 'url("/bg_pattern.jpg")' }}>
      <div id="MapContainer">
        <img src={RifugioBoreale} alt="Map" id="MapImage" onClick={handleMapClick} onContextMenu={handleMapClick} />

        {coordinates.map((item, index) => {
          const { x, y, channel, mobType, user, showOnHover, session: markerSession } = item;
          const backgroundColor = mobType === MobType.Condottiero ? "red" : "#A7F815";
          const borderSize = showOnHover ? "5px" : "1px";

          return session === markerSession && channel === channelState ? (
            <div
              key={index}
              className="marker"
              style={{ border: `${borderSize} solid black`, left: `${x - 10}px`, top: `${y - 10}px`, backgroundColor: backgroundColor }}
              title={`CH${channel} | ${mobType} | ${user}`}
            />
          ) : (
            <Fragment key={index} />
          );
        })}

        {roomsState.map((item) => {
          const { coords = [], color, showOnHover } = item;
          return coords.map((item, index) => {
            const { topLeft, bottomRight } = item;
            return (
              <div
                key={index}
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  opacity: showOnHover ? 0.6 : 0.15,
                  left: `${topLeft[0]}px`,
                  top: `${topLeft[1]}px`,

                  width: `${bottomRight[0] - topLeft[0]}px`,
                  height: `${bottomRight[1] - topLeft[1]}px`,
                  backgroundColor: color,
                  zIndex: 899,
                }}
              />
            );
          });
        })}

        {Object.entries(roomNumberList).map(([key, [x, y]], index) => {
          return (
            <span
              key={index}
              id="RoomNumber"
              style={{
                left: `${x - 10}px`,
                top: `${y - 18}px`,
                pointerEvents: "none",
              }}
            >
              {key}
            </span>
          );
        })}
      </div>
      <table id="Ronda">
        <thead>
          <tr>
            <td>Stanze</td>
            <td>{/* COLOR */}</td>
          </tr>
        </thead>
        <tbody>
          {roomsState.map((room, index) => {
            return (
              <tr
                key={index}
                onMouseEnter={() => setRoomsState((prev) => prev.map((room, i) => (i === index ? { ...room, showOnHover: true } : room)))}
                onMouseLeave={() => setRoomsState((prev) => prev.map((room, i) => (i === index ? { ...room, showOnHover: false } : room)))}
              >
                <td>{room.rooms}</td>
                <td style={{ backgroundColor: room.color }}></td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div id="TableContainer">
        {coordinates.length === 0 ? (
          <h1>Nessun punto inserito</h1>
        ) : (
          <table id="Coordinates">
            <thead>
              <tr>
                <td>Canale</td>
                <td>Boss</td>
                <td>Utente</td>
                <td>{/* TIMESTAMP */}</td>
                <td>{/* REMOVE */}</td>
              </tr>
            </thead>
            <tbody>
              {coordinates.map((coord, index) => {
                const { channel, mobType, user, timestamp, session: coordSession, _id } = coord;
                return session === coordSession ? (
                  <tr
                    key={index}
                    onMouseEnter={() => setCoordinates((prevCoordinates) => prevCoordinates.map((coord, i) => (i === index ? { ...coord, showOnHover: true } : coord)))}
                    onMouseLeave={() => setCoordinates((prevCoordinates) => prevCoordinates.map((coord, i) => (i === index ? { ...coord, showOnHover: false } : coord)))}
                  >
                    <td>{channel}</td>
                    <td>{mobType}</td>
                    <td>{user}</td>
                    <td>{formatTime(timestamp)}</td>

                    {user === username && _id ? (
                      <td>
                        <img src={closeIcon} alt="Delete" onClick={() => deleteCoordinate(_id)} />
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                ) : (
                  <Fragment key={index} />
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      <div id="ChannelList">
        <h2>Canale: </h2>
        {channels.map((item, index) => {
          return (
            <span key={index} onClick={() => setChannelState(item.value)} className={channelState === item.value ? "selectedChannel" : ""}>
              {item.label}
            </span>
          );
        })}
      </div>
      <input maxLength={16} type="text" id="UsernameInput" value={username} onChange={handleUsernameChange} placeholder="Username" />

      <div id="Legenda">
        <h2>Condottiero: Left click</h2>
        <h2>Generale: Right click</h2>
      </div>
    </div>
  );
};
export { MapRoute };
