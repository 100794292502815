import "./Okey.css";
import { FunctionComponent, useState } from "react";
import { card, COLORS } from "./Card";
import OkeyCombinations from "./OkeyCombinations";
import OkeyField from "./OkeyField";
import OkeyFooter from "./OkeyFooter";

const generateDeck = function () {
  const deckColors: COLORS[] = [COLORS.BLUE, COLORS.YELLOW, COLORS.RED];
  const numbers: number[] = Array.from({ length: 8 }, (_, i) => i + 1);
  const deck: card[] = deckColors.flatMap((cardColor) => {
    return numbers.map((cardNumber) => {
      return { cardColor: cardColor, used: false, cardNumber: cardNumber } as card;
    });
  });
  return deck;
};

const Okey: FunctionComponent = () => {
  document.title = "Gioco di carte Okey";
  const [cards, setCards] = useState<card[]>(generateDeck());
  const filteredCards = cards.filter(({ used }) => used === false); //tutte le carte NON USATE

  return (
    <div id="Okey">
      <OkeyField
        cards={cards}
        selectCard={(index) => {
          setCards((prev) => {
            prev[index].used = !prev[index].used;
            return [...prev];
          });
        }}
      />
      <OkeyCombinations cards={filteredCards} />
      <OkeyFooter reset={() => setCards(generateDeck())} />
    </div>
  );
};

export default Okey;
