import "./App.css";
import { Routes, Route } from "react-router-dom";
import Okey from "./routes/Okey/Okey";
import PersonalDetails from "./routes/personalDetails/PersonalDetails";
import { ReNew } from "./routes/ReNew/ReNew";
import { MapRoute } from "./routes/MapRoute/MapRoute";
import "./routes/MapRoute/MapRoute.css";

function App() {
  return (
    <Routes>
      <Route path="/okey" element={<Okey />} />
      <Route path="/re" element={<ReNew />} />
      <Route path="/map" element={<MapRoute />} />
      <Route path="*" element={<PersonalDetails />} />
    </Routes>
  );
}

export default App;
