import "./Okey.css";
import { FunctionComponent } from "react";
import resetIcon from "../../resources/reload.png";

type okeyFooter = {
  reset?: () => void;
};

const OkeyFooter: FunctionComponent<okeyFooter> = ({ reset = () => {} }) => {
  return (
    <div id="OkeyFooter">
      <img
        className="actions"
        title="reset"
        onClick={() => {
          reset();
        }}
        tabIndex={0}
        src={resetIcon}
        alt="reset"
      />
    </div>
  );
};
export default OkeyFooter;
