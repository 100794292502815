import "./HeaderSection.css";
import { FunctionComponent } from "react";
import profilePic from "../resources/profile_pic.jpg";

const HeaderSection: FunctionComponent = () => {
  return (
    <div id="HeaderSection">
      <img src={profilePic} alt="profilePic" className="profilePic" />
      <h1>Giovanni Pagliuca</h1>
      <h2>React Developer</h2>
      <h3>Milan, Italy</h3>
      <span>I get bored too easily, that's why I automate everything</span>
    </div>
  );
};

export default HeaderSection;
