import "./ReNew.css";
import { FunctionComponent } from "react";
import logoIcon from "../../resources/logo.png";
import resetIcon from "../../resources/reload.png";
import undoIcon from "../../resources/undo.png";

const Header: FunctionComponent<{ reset: () => void; undo: () => void }> = ({ reset, undo }) => {
  return (
    <div id="ReHeader">
      <img src={logoIcon} alt="logo" className="logo" />
      <img src={resetIcon} alt="reset" title="reset" className="reset" onClick={reset} />
      <img src={undoIcon} alt="undo" title="undo" className="undo" onClick={undo} />
    </div>
  );
};

export { Header };
