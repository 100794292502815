import { FunctionComponent } from "react";

// type cardColor = "blue" | "yellow" | "red";

enum COLORS {
  RED = "red",
  BLUE = "blue",
  YELLOW = "yellow",
}

interface card {
  cardNumber: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  cardColor: COLORS;
  used: boolean;
}

const Card: FunctionComponent<{ card: card; selectCard: () => void }> = ({ card, selectCard }) => {
  return (
    <span id="OkeyCard" onClick={selectCard} className={card.cardColor}>
      {card.cardNumber}
      {card.used === true && <div id="OkeyCross" />}
    </span>
  );
};

export { Card, type card, COLORS };
