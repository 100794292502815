import "./ReNew.css";
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from "react";
import { Header } from "./Header";
import { Body } from "./Body";
import { Footer } from "./Footer";

enum COLORS {
  METIN2BG = "var(--metin2-background)",
  WHITE = "white",
  GREEN = "green",
  ORANGE = "orange",
  YELLOW = "yellow",
  PURPLE = "purple",
  BLUE = "blue",
  RED = "red",
  BLACK = "black",
}

enum CARDS {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  KING = "K",
}

type positionName = string;

type BoxProps = {
  boxNumber: undefined | CARDS;
  color: COLORS;
  selected: boolean;
  positionName: positionName;
  selectBox?: () => void;
  updateBoxNumber?: (value: undefined | CARDS) => void;
  updateBoxColor?: (value: COLORS) => void;
};

const colors: COLORS[] = [COLORS.METIN2BG, COLORS.GREEN, COLORS.ORANGE, COLORS.YELLOW, COLORS.PURPLE, COLORS.BLUE, COLORS.RED];
const cards: CARDS[] = [CARDS.ONE, CARDS.TWO, CARDS.THREE, CARDS.FOUR, CARDS.FIVE, CARDS.KING];

const generateGrid = function () {
  const letters = ["A", "B", "C", "D", "E"] as const;
  const numbers = [1, 2, 3, 4, 5] as const;
  let grid: BoxProps[] = [];

  letters.forEach((letter) => {
    numbers.forEach((number) => {
      grid.push({
        selected: false,
        color: COLORS.METIN2BG,
        positionName: `${letter}${number}`,
        boxNumber: undefined,
      });
    });
  });

  return grid;
};

const ReNew: FunctionComponent<{}> = () => {
  document.title = "Trova il Re";
  const [history, setHistory] = useState<BoxProps[][]>([]);

  const addHistory = function (moves: BoxProps[], selection: boolean = false) {
    setHistory((prev) => {
      const newHistory = prev.map((moves) => {
        const newMoves: BoxProps[] = moves.map((item) => {
          return { ...item, selected: false };
        });

        return [...newMoves];
      });

      if (!selection) {
        newHistory.push(moves);
        return newHistory;
      }

      newHistory[newHistory.length - 1] = moves;
      return newHistory;
    });
  };

  const grid = history[history.length - 1];

  const initHistory = useCallback(() => {
    const newGrid = generateGrid();
    setHistory([newGrid]);
  }, []);

  const undo = function () {
    setHistory((prev) => {
      if (prev.length === 1) return [[...prev[0]]];
      const newHistory = [...prev];
      return newHistory.slice(0, newHistory.length - 1);
    });
  };

  useEffect(() => {
    initHistory();
  }, [initHistory]);

  if (history.length === 0) return <Fragment />;

  return (
    <div id="ReNew">
      <Header reset={initHistory} undo={undo} />
      <Body grid={grid} addHistory={addHistory} />
      <Footer grid={grid} addHistory={addHistory} />
    </div>
  );
};

export { ReNew, type BoxProps, CARDS, COLORS, colors, cards };
